
<mat-card class="create-request-card">
  <mat-card-title>Create a New Change Request</mat-card-title>
  <mat-card-header>BWI Rule Modification Request</mat-card-header>
  <br>
  <mat-divider></mat-divider>
  <mat-card-content>
    <form [formGroup]="ruleChangeForm">
      <div class="form-header">
        <div class="form-buttons">
          <button mat-raised-button color="primary" type="submit" (click)="onSaveDraft()">Save Draft</button>
          <button mat-raised-button color="primary" type="button" (click)="nextStep()">Next</button>
        </div>
      </div>
      <div>
        <p>Create a New Change Request </p>

        <mat-form-field class="input-field" appearance="outline">
          <mat-label>Owner</mat-label>
          <mat-select formControlName="owner" required>
            <mat-option value="group1">Owner1</mat-option>
            <mat-option value="group2">Owner2</mat-option>
          </mat-select>
          <mat-error *ngIf="ruleChangeForm.get('owner')?.hasError('required')">
            Owner is required
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="input-field" appearance="outline">
          <mat-label>Subject</mat-label>
          <input matInput placeholder="Enter subject" formControlName="subject">
          <mat-error *ngIf="ruleChangeForm.get('subject')?.hasError('required')">
            Subject is required
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="input-field" appearance="outline">
          <mat-label>Due</mat-label>
          <input matInput [matDatepicker]="picker1" formControlName="due">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <mat-error *ngIf="ruleChangeForm.get('due')?.hasError('required')">
            Due date is required
          </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field class="input-field" appearance="outline">
        <mat-label>Describe the Issue</mat-label>
        <textarea matInput formControlName="issueDescription"></textarea>
        <mat-error *ngIf="ruleChangeForm.get('issueDescription')?.hasError('required')">
          Issue description is required
        </mat-error>
      </mat-form-field>

      <div class="form-group" appearance="outline">
        <label class="attachments-label">Attachments</label>
        <input type="file" multiple (change)="onFileSelected($event)" #fileInput>
        <button type="button" mat-raised-button color="primary" (click)="fileInput.click()">
          <mat-icon>attach_file</mat-icon>
          Add more files
        </button>
        <ul>
          <li *ngFor="let item of files">{{ item.name }}</li>
        </ul>
      </div>

      <mat-form-field class="input-field" appearance="outline">
        <mat-label>Expires</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="expires">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="ruleChangeForm.get('expires')?.hasError('required')">
          Expiry date is required
        </mat-error>
      </mat-form-field>

      <div class="form-row">
        <mat-form-field class="input-field" appearance="outline">
          <mat-label>Requestor</mat-label>
          <input matInput required placeholder="Enter requestor" formControlName="requestor" value="Tim.Backeler.ext@bwi.de">
          <mat-error *ngIf="ruleChangeForm.get('requestor')?.hasError('required')">
            Requestor is required
          </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field class="input-field" appearance="outline">
        <mat-label>Device Name</mat-label>
        <mat-select formControlName="deviceName">
          <mat-option value="device1">Device 1</mat-option>
          <mat-option value="device2">Device 2</mat-option>
          <mat-option value="device3">Device 3</mat-option>
        </mat-select>
        <mat-error *ngIf="ruleChangeForm.get('deviceName')?.hasError('required')">
          Device name is required
        </mat-error>
      </mat-form-field>

    </form>
  </mat-card-content>
</mat-card>


<div class="form-container">
  <mat-label>Rule to modify:</mat-label>
  <mat-card>
    <mat-card-content>
      <div>
        <button mat-raised-button color="primary">Select Rules</button>
      </div>

    </mat-card-content>
  </mat-card>

  <mat-label>External change request id</mat-label>
  <mat-form-field appearance="outline" class="input-field">
    <input matInput placeholder="">
  </mat-form-field>

  <div class="workflow-info">
    <p>Workflow: BWI-Rule-Removal-Parallel-Approval-Workflow</p>
    <p>From Template: BWI Rule Removal Request</p>
  </div>

  <div class="form-header">
    <button mat-raised-button color="primary" class="back-button" type="button" (click)="prevStep()">Back</button>
    <div class="form-buttons">
      <button mat-raised-button color="primary" type="submit">Save Draft</button>
      <button mat-raised-button color="primary" type="button">Next</button>
    </div>
  </div>
</div>

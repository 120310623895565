import { NgModule } from '@angular/core';
import { RegelAendernComponent } from './regel-aendern.component';
import { SharedModule } from '../../Shared/shared.module';
import {RegelaendernRoutingModule} from "./regelaendern-routing.module";

@NgModule({
  declarations: [
    RegelAendernComponent,
  ],
  imports: [
    RegelaendernRoutingModule,
    SharedModule,
  ]
})
export class RegelAendernModule { }

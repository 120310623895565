import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AlertService} from "../../alert.service";

@Component({
  selector: 'app-regel-aendern',
  templateUrl: './regel-aendern.component.html',
  styleUrl: './regel-aendern.component.scss'
})
export class RegelAendernComponent {
  currentStep = 1;
  files: File[] = [];
  ruleChangeForm: FormGroup;

  constructor(private fb: FormBuilder, private alertService: AlertService ) {
    this.ruleChangeForm = this.fb.group({
      owner: ['', Validators.required],
      subject: ['', Validators.required],
      due: ['', Validators.required],
      issueDescription: ['', Validators.required],
      attachments: [''],
      expires: ['', Validators.required],
      requestor: ['', Validators.required],
      deviceName: ['', Validators.required]
    });
  }

  onSaveDraft() {
    if (this.ruleChangeForm.valid) {
      console.log(this.ruleChangeForm.value, );
      this.alertService.showAlert('Daten erfolgreich gespeichert!', 'success');
    } else {
      console.error('Form is invalid',);
      this.alertService.showAlert('Beim Speichern der Daten ist ein Fehler aufgetreten.', 'error');
    }
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      for (let i = 0; i < input.files.length; i++) {
        this.files.push(input.files[i]);
      }

    }
  }

  nextStep() {
    if (this.currentStep == 1){
      this.currentStep++
    }
  }

  prevStep() {
    if (this.currentStep == 2){
      this.currentStep--
    }
  }
}
